<template>
    <div class="grid justify-items-center">
        <img 
            src="../assets/images/exclusives/text-image.jpg"
            class="rounded-lg shadow-xl mt-6 sm:mt-8 h-auto w-96"
        />
        <div class="mx-5 sm:mx-16 md:mx-20 grid grid-cols-1 sm:grid-cols-2 items-center gap-5 sm:gap-7">
            <img src="../assets/images/exclusives/1.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/2.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/3.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/4.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/5.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/6.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/7.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/8.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/9.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/10.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/11.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/12.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/13.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/14.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/15.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/16.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/17.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/exclusives/18.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
        </div>
    </div>
</template>